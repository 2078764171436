import React, {useEffect, useState, useContext} from 'react';
import {VisibilityContext} from '../main.js';
import {useRect} from '../hooks/useRect.js';

export default function Passage(props) {
  const [visible, setVisible] = React.useState(props.visible);
  const {otherVisibilities, setOtherVisiblity, scrollPack} = useContext(VisibilityContext);
  const ref = React.useRef(null);

  useEffect(() => {
    const box = ref.current.getBoundingClientRect();

    if (box.top >= 0 && box.bottom <= window.innerHeight) {
      add();
      subtract();
    }
  })

  return (<p id={props.id} className="passage" ref={ref}>{props.children}</p>);

  function add() {
    if (!props.adds) {
      return
    }
    console.log(props.id, "adding", props.adds);

    ensureIsArray(props.adds).forEach((target, i) => {
      setOtherVisiblity(Object.assign(otherVisibilities, {[target]: true}));
    });
  }

  function subtract() {
    if (!props.subtracts) {
      return
    }
    console.log(props.id, "subtracting", props.subtracts);

    ensureIsArray(props.subtracts).forEach((target, i) => {
      if (targetIsAtTop(target)) {
            const box = document.getElementById(target).getBoundingClientRect();
            const sectionHeight = [...document.querySelectorAll(`[id=${target}]`)]
              .map((el) => el.getBoundingClientRect())
              .map((box) => box.height)
              .reduce((acc, curr) => acc += curr);
            window.scrollTo({top: scrollPack.scrollY - sectionHeight, behavior: "instant"});
      }
      setOtherVisiblity(Object.assign(otherVisibilities, {[target]: false}));
    });

  }

  function targetIsAtTop(id) {
    const target = document.getElementById(id);
    const base = document.getElementById("base");

    if (target && base) {
      const leafs = [...base.children].map(collapseToLeafs).reduce((acc, curr) => acc.concat(curr));

      return leafs.indexOf(target) == 0;
    } else {
      return false;
    }
  }

  function collapseToLeafs(root) {
    if ([...root.children].length == 0) {
      return [root];
    } else {
      let leafs = [];
      [...root.children].forEach((child, i) => {
        if ([...child.children].length > 0) {
          leafs.concat(collapseToLeafs(child));
        } else {
          leafs.push(child);
        }
      });
      return leafs;
    }
  }

  function ensureIsArray(arg) {
    if (Array.isArray(arg)) {
      return arg;
    } else {
      return [arg];
    }
  }
}
